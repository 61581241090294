import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get, onValue, update } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDjiBR7tqK8s3R_Kw_7uSOoF7G5S2fyKbA",
  authDomain: "unico-1a208.firebaseapp.com",
  databaseURL: "https://unico-1a208-default-rtdb.firebaseio.com",
  projectId: "unico-1a208",
  storageBucket: "unico-1a208.firebasestorage.app",
  messagingSenderId: "750406699715",
  appId: "1:750406699715:web:4280487723bd01704e3076",
  measurementId: "G-DNB3P6HH2S"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database, ref, set, get, onValue, update  };
