// import React from 'react';
// import Layout from './Layout';
// import RegistrationForm from './components/Form1';
// import InvestmentForm from './components/InvestmentForm';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';

// function App() {
//   return (
//     <BrowserRouter>
//       <Layout>
//         <Routes>
//           <Route path="/" element={<RegistrationForm />} />
//           <Route path="/investment" element={<InvestmentForm />} />
//         </Routes>
//       </Layout>
//     </BrowserRouter>
//   );
// }

// export default App;

import React from 'react'
import Form1 from './components/Form1';

const App = () => {
  return (
    <div>
      <Form1/>
    </div>
  )
}

export default App
