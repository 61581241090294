// App.js
import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import RegistrationForm from './components/Form1';
import InvestmentForm from './components/InvestmentForm';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Reinvestment from './components/Reinvestment';
import Dashboard from './components/Dashboard';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  if (!isLoggedIn) {
    return (
      
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/register" element={<Register setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    
    <BrowserRouter>
     <Layout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}>
        <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route path="/registration-form" element={<RegistrationForm />} />
          <Route path="/investment" element={<InvestmentForm />} />
          <Route path='/re-investment' element={<Reinvestment />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;