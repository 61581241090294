import React from 'react';
import logo from './components/assest/logo.jpg';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Layout = ({ children, isLoggedIn, setIsLoggedIn }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    toast.success('You have been logged out successfully!');
    navigate('/login');
  };

  return (
    <div>
      <div style={{ backgroundColor: '#f0f0f0', padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ width: '100px', height: '100px', borderRadius: '50%', marginRight: '20px' }} />
          <h2>UNICO EMPRESA</h2>
        </div>
        {isLoggedIn && (
          <button style={{ backgroundColor: '#4CAF50', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }} onClick={handleLogout}>
            Logout
          </button>
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '200px', backgroundColor: '#f0f0f0', padding: '20px' }}>
          <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
          <li style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
              <Link to="/" style={{ textDecoration: 'none', color: '#333' }}>Dashboard</Link>
            </li>
          
            <li style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
              <Link to="/registration-form" style={{ textDecoration: 'none', color: '#333' }}>Registration Form</Link>
            </li>
            <li style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
              <Link to="/investment" style={{ textDecoration: 'none', color: '#333' }}>Investment Form</Link>
            </li>
            <li style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
              <Link to="/re-investment" style={{ textDecoration: 'none', color: '#333' }}>Re-Investment Form</Link>
            </li>
          </ul>
        </div>
        <div style={{ flex: 1, padding: '20px' }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;