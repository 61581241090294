import React, { useState, useEffect } from "react";
import { database, ref, set, get, push } from "../firebase";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assest/logo.jpg'



const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    ref: "",
    formNo: "",
    date: "",
    customerName: "",
    addressLine1: "",
    addressLine2: "",
    pinCode: "",
    taluka: "",
    district: "",
    state: "",
    adharCardNo: "",
    panCardNo: "",
    phoneNo1: "",
    phoneNo2: "",
    accountName: "",
    bankName: "",
    accountNo: "",
    branch: "",
    ifscCode: "",
    accountType: "",
    googlePayNo: "",
    phonePayNo: "",
    remark: "",
  });

  const [formCounter, setFormCounter] = useState(1);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [formNo, setFormNo] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [refSearchTerm, setRefSearchTerm] = useState("");
  const [refSearchResults, setRefSearchResults] = useState([]);

  useEffect(() => {
    const fetchDates = async () => {
      const datesRef = ref(database, "customers");
      const snapshot = await get(datesRef);
      if (snapshot.exists()) {
        const dates = Object.keys(snapshot.val()); // Extract dates from the snapshot
        setDates(dates);
      } else {
        console.log("No dates available in the database.");
      }
    };

    fetchDates();
  }, []);

  useEffect(() => {
    const counterRef = ref(database, "formCounter");
    get(counterRef).then((snapshot) => {
      if (snapshot.exists()) {
        setFormCounter(snapshot.val());
      } else {
        set(counterRef, 1);
        setFormCounter(1);
      }
    });
    const currentDate = new Date().toISOString().split('T')[0];
    setFormData((prev) => ({ ...prev, date: currentDate }));
  }, []);
  

  useEffect(() => {
    const fetchRefSearchResults = async () => {
      if (refSearchTerm.trim() !== "") {
        const customersRef = ref(database, "customers");
        const snapshot = await get(customersRef);
        if (snapshot.exists()) {
          const customers = Object.values(snapshot.val());
          const searchResults = customers.filter(customer => customer.customerName.toLowerCase().includes(refSearchTerm.toLowerCase()));
          setRefSearchResults(searchResults);
        } else {
          console.log("No customers available in the database.");
        }
      } else {
        setRefSearchResults([]);
      }
    };
  
    fetchRefSearchResults();
  }, [refSearchTerm]);


  useEffect(() => {
    if (isFormSubmitted) {
      const date = new Date().toISOString().split('T')[0];
      const customerRef = ref(database, `customers/${formData.formNo}`);
      set(customerRef, { ...formData, date })
        .then(() => {
          toast.success("Form submitted successfully!");
          clearForm();
          setIsFormSubmitted(false);
        })
        .catch((error) => toast.error("Error: " + error.message));
    };
  }, [isFormSubmitted, formData]);

  useEffect(() => {
    const fetchCustomers = async () => {
      const customersRef = ref(database, "customers");
      const snapshot = await get(customersRef);
      if (snapshot.exists()) {
        const customers = Object.values(snapshot.val());
        setCustomers(customers);
      } else {
        console.log("No customers available in the database.");
      }
    };

    fetchCustomers();
  }, []);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isUpdateMode) {
      updateCustomer();
    } else {
      setFormData((prev) => ({ ...prev, formNo: formCounter }));
      setIsFormSubmitted(true);
      setFormCounter(formCounter + 1); // Update the form counter
      set(ref(database, "formCounter"), formCounter + 1); // Update the form counter in the database
    }
  };


  const updateCustomer = () => {
    const customerRef = ref(database, `customers/${selectedCustomer.formNo}`);
    set(customerRef, formData)
      .then(() => {
        toast.success("Customer updated successfully!");
        clearForm();
        setIsUpdateMode(false);
      })
      .catch((error) => toast.error("Error: " + error.message));
  };

  const clearForm = () => {
    setFormData({
      ref: "",
      formNo: "",
      date: "",
      customerName: "",
      addressLine1: "",
      addressLine2: "",
      pinCode: "",
      taluka: "",
      district: "",
      state: "",
      adharCardNo: "",
      panCardNo: "",
      phoneNo1: "",
      phoneNo2: "",
      accountName: "",
      bankName: "",
      accountNo: "",
      branch: "",
      ifscCode: "",
      accountType: "",
      googlePayNo: "",
      phonePayNo: "",
      remark: "",
    });
  };



  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRefSearchTermChange = (e) => {
    setRefSearchTerm(e.target.value);
    setFormData((prev) => ({ ...prev, ref: e.target.value }));
    const fetchRefSearchResults = async () => {
      if (e.target.value.trim() !== "") {
        const customersRef = ref(database, "customers");
        const snapshot = await get(customersRef);
        if (snapshot.exists()) {
          const customers = Object.values(snapshot.val());
          const searchResults = customers.filter(customer => customer.customerName.toLowerCase().includes(e.target.value.toLowerCase()));
          setRefSearchResults(searchResults);
        } else {
          console.log("No customers available in the database.");
        }
      } else {
        setRefSearchResults([]);
      }
    };
    fetchRefSearchResults();
  };

  const printForm = (customer) => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);
  
    iframe.contentWindow.document.write(`
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
            }
            .header {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 20px;
              color: rgb(50, 52, 54);
            }
            .logo-container {
              width: 80px;
              height: 80px;
            
              overflow: hidden;
              margin-right: 20px;
             
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .logo-container img {
              width: 70%;
              height: auto;
            }
            .form-table {
              width: 100%;
              border-collapse: collapse;
              border: 1px solid #ddd;
            }
            .form-table th, .form-table td {
              border: 1px solid #ddd;
              padding: 10px;
              text-align: left;
            }
            .photo-field {
              width: 150px;
              height: 150px;
              border: 1px solid #ddd;
              text-align: center;
              vertical-align: middle;
              line-height: 150px;
              position: absolute;
              right: 35px;
              top: 250px;
            }
            .signature {
              margin-top: 26px;
              text-align: right;
              color: rgb(0, 0, 0);
            }
            .section-header {
              font-size: 18px;
              font-weight: bold;
              margin-bottom: 10px;
              color: rgb(0, 0, 0);
            }
            .table-header {
              background-color: rgb(88, 84, 84);
              color: #fff;
              padding: 10px;
              text-align: center;
            }
          </style>
        </head>
        <body>
          <div class="header">
            <div class="logo-container">
               <img src=${logo} alt="UNICO Logo" />
            </div>
            <div>UNICO EMPRESA</div>
          </div>
        
  
          <table class="form-table">
            <tr class="table-header">
              <th colspan="6">Customer Information</th>
            </tr>
            <tr>
              <th>REF:</th>
              <td colspan="3">${customer.ref}</td>
              <th>DATE:</th>
              <td>${customer.date}</td>
            </tr>
            <tr>
              <th>FORM NO:</th>
              <td colspan="5">${customer.formNo}</td>
            </tr>
          </table>
  
          <table class="form-table">
            <tr class="table-header">
              <th colspan="6">Customer Details</th>
            </tr>
            <tr>
              <th>Customer Name:</th>
              <td colspan="3">${customer.customerName}</td>
            </tr>
            <tr>
              <th>Address:</th>
              <td colspan="3">${customer.addressLine1}, ${customer.addressLine2}</td>
            </tr>
            <tr>
              <th>PIN Code:</th>
              <td>${customer.pinCode}</td>
              <th>Taluka:</th>
              <td>${customer.taluka}</td>
            </tr>
            <tr>
              <th>District:</th>
              <td>${customer.district}</td>
              <th>State:</th>
              <td>${customer.state}</td>
            </tr>
            <tr>
              <td></td>
              <td colspan="3">
                <div style="
                  width: 150px; 
                  height: 150px; 
                  border: 1px solid #ddd; 
                  text-align: center; 
                  line-height: 150px; 
                  margin-left: auto;">
                  PHOTO
                </div>
              </td>
            </tr>
          </table>
  
          <table class="form-table">
            <tr class="table-header">
              <th colspan="6">Identification Details</th>
            </tr>
            <tr>
              <th>Aadhar Card No:</th>
              <td>${customer.adharCardNo}</td>
              <th>PAN Card No:</th>
              <td>${customer.panCardNo}</td>
            </tr>
            <tr>
              <th>Phone No 1:</th>
              <td>${customer.phoneNo1}</td>
              <th>Phone No 2:</th>
              <td>${customer.phoneNo2}</td>
            </tr>
          </table>
  
          <table class="form-table">
            <tr class="table-header">
              <th colspan="6">Bank Account Details</th>
            </tr>
            <tr>
              <th>Account Name:</th>
              <td>${customer.accountName}</td>
              <th>Bank Name:</th>
              <td>${customer.bankName}</td>
            </tr>
            <tr>
              <th>Account No:</th>
              <td>${customer.accountNo}</td>
              <th>Branch:</th>
              <td>${customer.branch}</td>
            </tr>
            <tr>
              <th>IFSC Code:</th>
              <td>${customer.ifscCode}</td>
              <th>Account Type:</th>
              <td>${customer.accountType}</td>
            </tr>
            <tr>
              <th>Google Pay No:</th>
              <td>${customer.googlePayNo}</td>
              <th>Phone Pay No:</th>
              <td>${customer.phonePayNo}</td>
            </tr>
          </table>
  
          <table class="form-table">
            <tr class="table-header">
              <th colspan="6">Additional Information</th>
            </tr>
            <tr>
              <th>Remark:</th>
              <td colspan="3">${customer.remark}</td>
            </tr>
          </table>
  
          <div class="signature">Customer Signature: ${customer.customerName}</div>
        </body>
      </html>
    `);
  
    iframe.contentWindow.print();
    // iframe.remove();
  };
  

  const updateCustomerForm = (customer) => {
    setFormData(customer);
    setSelectedCustomer(customer);
    setIsUpdateMode(true);
  };

  const deleteCustomer = (customer) => {
    const customerRef = ref(database, `customers/${customer.formNo}`);
    set(customerRef, null)
      .then(() => {
        toast.success("Customer deleted successfully!");
        const updatedCustomers = customers.filter((c) => c.formNo !== customer.formNo);
        setCustomers(updatedCustomers);
      })
      .catch((error) => toast.error("Error: " + error.message));
  };

  const containerStyle = {
    maxWidth: "800px",
    margin: "20px auto",
    padding: "20px",
    border: "1px solid #000",
    fontFamily: "Arial, sans-serif",
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  };

  const cellStyle = {
    border: "1px solid #000",
    padding: "10px",
  };

  const inputStyle = {
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    width: "80%",
  };

  const submitButtonStyle = {
    padding: "10px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
    width: "50%",
    display: "block",
    margin: "0 auto",
  };

  const actionButtonContainerStyle = {
    display: "flex",
    justifyContent: "center",
    gap: "10px", // Adds space between buttons
  };

  return (
    <div style={containerStyle}>
    <h2 style={{ textAlign: "center", display: "flex", alignItems: "center" }}>
      <img src={logo} alt="Logo" style={{ width: "70px", height: "70px", borderRadius: "50%", marginRight: "20px" }} />
      <span style={{ fontSize: "30px", fontWeight: "bold" }}>UNICO EMPRESA</span></h2>
    <form onSubmit={handleSubmit}>
        <table style={tableStyle}>
          <tbody>
            <tr>
            <td style={cellStyle}>REF:</td>
<td style={cellStyle}>
  <input type="text" name="ref" value={formData.ref} onChange={handleRefSearchTermChange} style={inputStyle} />
  {refSearchResults.length > 0 && (
    <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
      {refSearchResults.map((customer) => (
        <li key={customer.formNo} style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
          <span onClick={() => {
            setFormData((prev) => ({ ...prev, ref: customer.customerName }));
            setRefSearchTerm(customer.customerName);
            setRefSearchResults([]);
          }}>{customer.customerName}</span>
        </li>
      ))}
      <li key="no-match" style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
        <span onClick={() => {
          setFormData((prev) => ({ ...prev, ref: refSearchTerm }));
          setRefSearchResults([]);
        }}>No match found, use this value</span>
      </li>
    </ul>
  )}
</td>
<td style={cellStyle}>DATE:</td>
<td style={cellStyle}>
  <input type="date" name="date" value={formData.date} onChange={handleChange} style={inputStyle} />
</td>
            </tr>
            <tr>
              <td style={cellStyle}>FORM NO:</td>
              <td colSpan="3" style={cellStyle}>
                <input type="text" name="formNo" value={isUpdateMode ? formData.formNo : formCounter} readOnly  style={inputStyle} />
              </td>
            </tr>
          </tbody>
        </table>
        <h3 style={{ textAlign: "center" }}>Registration Form</h3>

        <table style={tableStyle}>
          <tbody>
            <tr>
              <td style={cellStyle}>Customer Name:</td>
              <td style={cellStyle}>
                <input type="text" name="customerName" onChange={handleChange} value={formData.customerName} style={inputStyle} required />
              </td>
              <td style={cellStyle}>Address Line 1:</td>
              <td style={cellStyle}>
                <input type="text" name="addressLine1" onChange={handleChange} value={formData.addressLine1}style={inputStyle} required />
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>Address Line 2:</td>
              <td style={cellStyle}>
                <input type="text" name="addressLine2" onChange={handleChange} value={formData.addressLine2}style={inputStyle} />
              </td>
              <td style={cellStyle}>PIN Code:</td>
              <td style={cellStyle}>
                <input type="text" name="pinCode" onChange={handleChange} style={inputStyle} value={formData.pinCode} required />
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>Taluka:</td>
              <td style={cellStyle}>
                <input type="text" name="taluka" onChange={handleChange} value={formData.taluka} style={inputStyle} required />
              </td>
              <td style={cellStyle}>District:</td>
              <td style={cellStyle}>
                <input type="text" name="district" onChange={handleChange}  value={formData.district} style={inputStyle} required />
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>State:</td>
              <td style={cellStyle}>
                <input type="text" name="state" onChange={handleChange} value={formData.state} style={inputStyle} required />
              </td>
              <td style={cellStyle}>Aadhar Card No:</td>
              <td style={cellStyle}>
              <input
  type="text"
  name="adharCardNo"
  onChange={(e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 12);
    const formattedValue = value.replace(/(\d{4})(\d{4})(\d{4})/, "$1 $2 $3");
    setFormData((prev) => ({ ...prev, adharCardNo: formattedValue }));
  }}
  value={formData.adharCardNo}
  style={inputStyle}
  required
/>
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>PAN Card No:</td>
              <td style={cellStyle}>
              <input
  type="text"
  name="panCardNo"
  onChange={(e) => {
    const value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, "").slice(0, 10);
    const formattedValue = value.replace(/([A-Z]{5})(\d{4})([A-Z])/, "$1$2$3");
    setFormData((prev) => ({ ...prev, panCardNo: formattedValue }));
  }}
  value={formData.panCardNo}
  style={inputStyle}
  required
  maxLength="10"
/>
              </td>
              <td style={cellStyle}>Phone No 1:</td>
              <td style={cellStyle}>
              <input
  type="text"
  name="phoneNo1"
  onChange={(e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 10);
    setFormData((prev) => ({ ...prev, phoneNo1: value }));
  }}
  value={formData.phoneNo1}
  style={inputStyle}
  required
/>
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>Phone No 2:</td>
              <td style={cellStyle}>
              <input
  type="text"
  name="phoneNo2"
  onChange={(e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 10);
    setFormData((prev) => ({ ...prev, phoneNo2: value }));
  }}
  value={formData.phoneNo2}
  style={inputStyle}
/>
              </td>
              </tr>
            
                </tbody>
                </table>
                <table style={tableStyle}>
                    <tbody>
                    <h3 style={{ textAlign: "center" }}>Registration Form</h3>
                <tr>
              <td style={cellStyle}>Account Name:</td>
              <td style={cellStyle}>
                <input type="text" name="accountName" onChange={handleChange} value={formData.accountName} style={inputStyle} required />
              </td>
           
              <td style={cellStyle}>Bank Name:</td>
              <td style={cellStyle}>
                <input type="text" name="bankName" onChange={handleChange} value={formData.bankName} style={inputStyle} required />
             </td>
             </tr>
             <tr>
              <td style={cellStyle}>Account No:</td>
              <td style={cellStyle}>
                <input type="text" name="accountNo" onChange={handleChange} value={formData.accountNo} style={inputStyle} required />
              </td>
          
              <td style={cellStyle}>Branch:</td>
              <td style={cellStyle}>
                <input type="text" name="branch" onChange={handleChange} value={formData.branch} style={inputStyle} required />
              </td>
              </tr>
              <tr>
              <td style={cellStyle}>IFSC Code:</td>
          <td style={cellStyle}>
            <input type="text" name="ifscCode" onChange={handleChange} value={formData.ifscCode} style={inputStyle} required />
          </td>
<td style={cellStyle}>Account Type:</td>
<td style={cellStyle}>
  <select
    name="accountType"
    value={formData.accountType}
    onChange={handleChange}
    style={inputStyle}
    required
  >
    <option value="">Select Account Type</option>
    <option value="Savings">Savings</option>
    <option value="Current">Current</option>
    <option value="Fixed Deposit">Fixed Deposit</option>
    <option value="Recurring Deposit">Recurring Deposit</option>
    <option value="NRI">NRI</option>
  </select>
</td>
              </tr>
              <tr>
              <td style={cellStyle}>Google Pay No:</td>
              <td style={cellStyle}>
              <input
  type="text"
  name="googlePayNo"
  onChange={(e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 10);
    setFormData((prev) => ({ ...prev, googlePayNo: value }));
  }}
  value={formData.googlePayNo}
  style={inputStyle}
/>
              </td>
           
              <td style={cellStyle}>Phone Pay No:</td>
              <td style={cellStyle}>
              <input
  type="text"
  name="phonePayNo"
  onChange={(e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 10);
    setFormData((prev) => ({ ...prev, phonePayNo: value }));
  }}
  value={formData.phonePayNo}
  style={inputStyle}
/>
              </td>
              </tr>
              <tr>
              <td style={cellStyle}>Remark:</td>
              <td colSpan="3" style={cellStyle}>
                <textarea name="remark" onChange={handleChange} value={formData.remark} style={{ ...inputStyle, height: "60px", width: "95%" }}></textarea>
              </td>
            </tr>
          </tbody>
        </table>

        <button type="submit" style={submitButtonStyle}>{isUpdateMode ? 'Update' : 'Submit'}</button>
      </form>

      <div>
        <h2>Search Customers</h2>
        <input type="text" value={searchTerm} onChange={handleSearchTermChange} placeholder="Search by form no or customer name" />
      </div>

      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={cellStyle}>Form No</th>
            <th style={cellStyle}>Customer Name</th>
            <th style={cellStyle}>Phone No</th>
            <th style={cellStyle}>Address</th>
            <th style={cellStyle}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {customers.filter(customer => customer.formNo.toString().includes(searchTerm) || customer.customerName.toLowerCase().includes(searchTerm.toLowerCase())).map(customer => (
            <tr key={customer.formNo}>
              <td style={cellStyle}>{customer.formNo}</td>
              <td style={cellStyle}>{customer.customerName}</td>
              <td style={cellStyle}>{customer.phoneNo1}</td>
              <td style={cellStyle}>{customer.addressLine1}, {customer.addressLine2}</td>
              <td style={cellStyle}>
                <div style={actionButtonContainerStyle}>
              <button onClick={() => printForm(customer)} style={{
  backgroundColor: '#4CAF50',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  padding: '10px 20px',
  fontSize: '16px',
  display: 'inline-block',
  verticalAlign: 'middle',
}}>
<i class="fa-solid fa-print"></i>
</button>
<button onClick={() => updateCustomerForm(customer)} style={{
  backgroundColor: '#03A9F4',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  padding: '10px 20px',
  fontSize: '16px',
  display: 'inline-block',
  verticalAlign: 'middle',
  marginLeft: '10px',
}}>
  <i className="fas fa-edit"></i>
 
</button>
<button onClick={() => deleteCustomer(customer)} style={{
  backgroundColor: '#FF0000',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  padding: '10px 20px',
  fontSize: '16px',
  display: 'inline-block',
  verticalAlign: 'middle',
  marginLeft: '10px',
}}>
  <i className="fas fa-trash-alt" ></i>

</button>
</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer/>
    </div>
  );
};
//9+4?

export default RegistrationForm;