import React, { useState, useEffect } from 'react';
import { database, ref, get, onValue } from '../firebase';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const [customers, setCustomers] = useState([]);
  const [investments, setInvestments] = useState([]);
  const [reinvestments, setReinvestments] = useState([]);
  const [receiptCounter, setReceiptCounter] = useState(0);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalReinvestment, setTotalReinvestment] = useState(0);
  
  
  useEffect(() => {
    const fetchCustomers = async () => {
      const customersRef = ref(database, 'customers');
      const snapshot = await get(customersRef);
      if (snapshot.exists()) {
        const customers = Object.values(snapshot.val());
        setCustomers(customers);
      }
    };

    const fetchInvestments = async () => {
      const investmentsRef = ref(database, 'investments');
      onValue(investmentsRef, (snapshot) => {
        if (snapshot.exists()) {
          const investments = Object.values(snapshot.val());
          setInvestments(investments);
          const totalInvestment = investments.reduce((acc, investment) => acc + parseInt(investment.amountInNumber), 0);
          setTotalInvestment(totalInvestment);
        }
      });
    };
  

    const fetchReinvestments = async () => {
      const reinvestmentsRef = ref(database, 'Reinvestments');
      const snapshot = await get(reinvestmentsRef);
      console.log('Reinvestments data:', snapshot.val());
      if (snapshot.exists()) {
        const reinvestments = Object.values(snapshot.val());
        console.log('Reinvestments:', reinvestments);
        setReinvestments(reinvestments);
        const totalReinvestment = reinvestments.reduce((acc, reinvestment) => acc + parseFloat(reinvestment.totalAmountInNumber), 0);
        console.log('Total Reinvestment:', totalReinvestment);
        setTotalReinvestment(totalReinvestment);
      } else {
        setTotalReinvestment(0);
      }
    
      onValue(reinvestmentsRef, (snapshot) => {
        console.log('Reinvestments data (onValue):', snapshot.val());
        if (snapshot.exists()) {
          const reinvestments = Object.values(snapshot.val());
          console.log('Reinvestments (onValue):', reinvestments);
          setReinvestments(reinvestments);
          const totalReinvestment = reinvestments.reduce((acc, reinvestment) => acc + parseFloat(reinvestment.totalAmountInNumber), 0);
          console.log('Total Reinvestment (onValue):', totalReinvestment);
          setTotalReinvestment(totalReinvestment);
        } else {
          setTotalReinvestment(0);
        }
      });
    };

    const fetchReceiptCounter = async () => {
      const receiptCounterRef = ref(database, 'receiptCounter');
      const snapshot = await get(receiptCounterRef);
      if (snapshot.exists()) {
        const receiptCounter = snapshot.val();
        setReceiptCounter(receiptCounter);
      }
    };

    fetchCustomers();
    fetchInvestments();
    fetchReinvestments();
    fetchReceiptCounter();
  }, []);





  return (
    <div style={{ fontFamily: 'Arial, sans-serif', margin: '20px', padding: '20px', borderRadius: '10px'}}>
      <main style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
        <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: '20px' }}>
          <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>Overview</h2>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginRight:'30px', alignItems: 'center', padding: '20px', backgroundColor: '#f0f0f0', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', width: '200px' }}>
              <i className="fas fa-users" style={{ fontSize: '48px', color: '#337ab7' }}></i>
              <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Total Customers</h3>
              <p style={{ fontSize: '24px', fontWeight: 'bold' }}>{customers.length}</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginRight:'30px', alignItems: 'center', padding: '20px', backgroundColor: '#f0f0f0', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', width: '200px' }}>
              <i className="fas fa-money-bill-alt" style={{ fontSize: '48px', color: '#337ab7' }}></i>
              <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Total Investments</h3>
              <p style={{ fontSize: '24px', fontWeight: 'bold' }}>{totalInvestment}Rs</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginRight:'30px', alignItems: 'center', padding: '20px', backgroundColor: '#f0f0f0', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', width: '200px' }}>
              <i className="fas fa-chart-line" style={{ fontSize: '48px', color: '#337ab7' }}></i>
              <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Total Reinvestments</h3>
              <p style={{ fontSize: '24px', fontWeight: 'bold' }}>{totalReinvestment}Rs</p>
            </div>
          </div>
        </section>
        
       
      </main>
    </div>
  );
};

export default Dashboard;